import React, { Suspense } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import './App.css';

const Search = React.lazy( () => import( './Plugins/Search' ) );
const Generator = React.lazy( () => import( './Plugins/Generator' ) );

const Unknown = ( { name } ) => (
	<div>{ `${ name } is not a known NB plugin.` }</div>
);

const Loading = () => (
	<Dimmer active>
		<Loader indeterminate>Gjør klar systemet</Loader>
	</Dimmer>
);

const getStartPlugin = ( name ) => {
	switch ( name ) {
		case 'generator':
			return Generator;
		case 'search':
			return Search;
		default:
			return Unknown;
	}
};

const getProps = ( name, props ) => {
	if ( name === 'search' ) {
		return {
			customItems: ( props.manualList && window[ props.manualList ] ) || [],
			customComments: ( props.manualCommentList && window[ props.manualCommentList ] ) || {},
		};
	}

	return {};
};

const App = ( { nbPlugin, ...props } ) => {
	const name = nbPlugin && nbPlugin.toLowerCase();
	const Plugin = getStartPlugin( name );
	const additinoalProps = getProps( name, props );

	return (
		<div className="nb-catalog-plugin">
			<Suspense fallback={ <Loading /> }>
				<Plugin name={ nbPlugin } { ...additinoalProps } { ...props } />
			</Suspense>
		</div>
	);
};

export default App;
