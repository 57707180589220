import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './semantic.js';

import App from './App';
import * as serviceWorker from './serviceWorker';

const selector = document.querySelectorAll( '[data-nb-plugin]' );

if ( selector ) {
	const elements = selector.length ? selector : [ selector ];

	elements.forEach( ( $el ) => {
		ReactDOM.render( <App { ...$el.dataset } />, $el );
	} );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
